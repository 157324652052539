import { useEffect, useState } from "react";
import StatCard from "../stat-card/stat-card";
import "./statistics.css";
import { getScanResultById, getUserProfile } from "../../services/resource-server/resource-server";
import { useAppSelector } from "../../app/hooks";

interface StatCardData {
    dataLabel: string;
    score: string;
    delta: string;
}

export default function Statistics() {
    let [profile, setProfile] = useState<any>(null);
    let [statCards, setStatCards] = useState<Array<StatCardData>>([]);
    let [primaryStat, setPrimaryStat] = useState<Object>({});
    let [previousStat, setPreviousStat] = useState<Object>({});

    const selectedScan = useAppSelector((state) => state.selectedScan.record);
    const graphedHistory = useAppSelector((state) => state.scanResults.history);

    useEffect(() => {        
        let stats_with_labels = [];
        for (const key in primaryStat) {
            const raw_value = primaryStat[key as keyof Object]
            if (key !== "nFit" && typeof raw_value !== "string") {
                const val = +raw_value;

                let delta = "";
                if (Object.keys(previousStat).length > 0 && typeof previousStat[key as keyof Object] !== "string" && previousStat[key as keyof Object] !== undefined) {
                    const prevVal = +previousStat[key as keyof Object];
                    const deltaVal = val - prevVal;
                    const roundedDelta = parseFloat(deltaVal.toPrecision(2)).toString();
                    delta = deltaVal > 0 ? `(+${roundedDelta})` : `(${roundedDelta})`;
                }
                const y = {
                    dataLabel: key,
                    score: parseFloat(val.toPrecision(3)).toString(),
                    delta: delta
                };
                stats_with_labels.push(y);
            }
        }
        setStatCards(stats_with_labels);
    }, [primaryStat, previousStat]);

    const loadPrimaryKeys = (id: number) => {
        const fetchData = async (id: number) => {
            const statRecord = await getScanResultById(id);
            setPrimaryStat(statRecord);
        }
        fetchData(id);
    };

    const loadPreviousKeys = (id: number) => {
        const fetchData = async (id: number) => {
            const statRecord = await getScanResultById(id);
            setPreviousStat(statRecord);
        }
        fetchData(id);
    };

    /* If the user selects a scan, match statistics */
    useEffect(() => {
        console.log('line 57 fired');
        const stats: any[] = [];
        const index = graphedHistory.findIndex(history => history.id === selectedScan?.id);
        if (index > -1) {
            const primary = graphedHistory[index];
            loadPrimaryKeys(primary.id);

            if (index > 0) {
                const previous = graphedHistory[index-1];
                loadPreviousKeys(previous.id);
            } else {
                setPreviousStat({});
            }
        } else {
            setPrimaryStat({});
        }
    }, [selectedScan, graphedHistory]);
    
    /* initial fetch of most recent statistics */
    useEffect(() => {
        if (graphedHistory !== undefined && graphedHistory.length > 0) {
            const latestRecord = [...graphedHistory].pop();

            if (latestRecord) {
                loadPrimaryKeys(latestRecord.id);
                const index = graphedHistory.findIndex(history => history.id === latestRecord.id);
    
                if (index && index > 0) {
                    loadPreviousKeys(graphedHistory[index - 1].id);
                };
            }
        }
    }, [graphedHistory]);

    /* This will fetch the user profile used to show the title with their name */
    useEffect(() => {
        const fetchData = async () => {
            const profile = await getUserProfile();
            const mappedProfile = {
                firstName: profile.first_name,
            };
            setProfile(mappedProfile);
        };
        fetchData();
    }, []);

    return (
        <div className="nFitStatistics">
            <div className="subTitle">{profile?.firstName}'s Stats</div>
            <div className="statCards">
                {statCards.map((cardData, index) => <StatCard
                    key={index}
                    dataLabel={cardData.dataLabel}
                    score={cardData.score}
                    delta={cardData.delta}
                    deltaUpDown={cardData.delta.indexOf("+") > -1 ? "up" : "down"}
                />)}
            </div>
        </div>
    );
}