import { GroupUserControl, GroupSummary } from '../components';

export default function Groups() {
    return (
        <>
            <div className="breadcrumb">
            neuroFit &gt; Group Progress
            </div>
            <div className="title">
            Group Progress
            </div>
            <GroupUserControl />
            <GroupSummary />
        </>
    );
}