import QRCode from "react-qr-code";
import { useAuth } from "../context";

export default function Scan() {
    const auth = useAuth();
    const user = auth?.user;
    const token = user.access_token;
    return (
        <>
            <div className="breadcrumb">
            neuroFit &gt; New Scan
            </div>
            <div className="title">
            New Scan
            </div>
            <div>
                <QRCode value={token} />
            </div>
        </>
    );
}