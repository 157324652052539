const endpoint_individual = 'individual/';
const endpoint_member_scans = 'member-scans/';
const endpoint_scanresult = 'scan_result/';
const endpoint_statcard = 'stat-cards/';
const endpoint_user_individual = 'user-individual/';
const endpoint_user_profile = 'user-profile/';
const endpoint_user_type = 'user_type/';
const endpoint_update_user_annotation = 'scan_result/';

export {
    endpoint_individual,
    endpoint_member_scans,
    endpoint_scanresult,
    endpoint_statcard,
    endpoint_user_individual,
    endpoint_user_profile,
    endpoint_user_type,
    endpoint_update_user_annotation,
};