function median(arr: number[]): number {
    if (arr.length === 0) return 0;

    arr.sort((a, b) => a - b);
    const mid = Math.round(arr.length / 2);

    return arr.length % 2 !== 0 ? arr[mid] : (arr[mid - 1] + arr[mid]) / 2;
}

function variance(arr: number[]): number {
    if (arr.length === 0) return 0;

    const mean = arr.reduce((sum, value) => sum + value, 0) / arr.length;
    const squaredDiffs = arr.map(value => (value - mean) ** 2);
    const avgSquaredDiff = squaredDiffs.reduce((sum, value) => sum + value, 0) / arr.length;

    return avgSquaredDiff;
}

function sigma(arr: number[]): number {
    if (arr.length === 0) return 0;

    arr.sort((a, b) => a - b);
    const index_25 = Math.round(arr.length * 0.25);
    const index_75 = Math.round(arr.length * 0.75);

    const sigma = (arr[index_75] - arr[index_25]) / (2 * 0.6745);

    return sigma;
}

export {
    median,
    variance,
    sigma,
};