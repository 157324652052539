import { useAppSelector } from "../../app/hooks";
import "./group-user-control.css";

export default function GroupUserControl() {
    const coachMembers = useAppSelector((state) => state.coachMembers.members);

    const initials = coachMembers.map(record => {
        return record.firstName.substring(0, 1) + record.lastName.substring(0, 1);
    });

    return (
        <div className="drop-shadow-content-box group-user-control">
            <h4>Group users</h4>
            <div>
                <div className="group-user-list">{
                    initials.map(record => <button key={record}>{record}</button>)
                }
                    <button className="selected">T</button>
                </div>

                <div className="user-filter">
                    <select>
                        <option selected disabled>Filter by user</option>
                    </select>
                    <button>Add user</button>
                    <button>Remove</button>
                </div>
            </div>
        </div>
    );
}