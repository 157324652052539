import { Link, NavLink, Navigate, Outlet } from "react-router-dom";
import { IonIcon } from '@ionic/react';
import { podiumOutline, peopleOutline, settingsOutline, speedometerOutline } from 'ionicons/icons';

import { useAuth } from "../../context";
import UserProfile from "./user-profile";
import './protected-routes.css';
import { getUserIndividual, setAuthToken } from "../../services/resource-server/resource-server";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setCoachMemberUsernames, setCoachMembers, SerializableIndividual } from "../../features/coach-members/coach-member-slice";

function ProtectedRoutes() {
    const dispatch = useAppDispatch();
    let [individual, setIndividual] = useState<any>(null);
    const auth = useAuth();

    useEffect(() => {
        if (auth === undefined || auth.user === undefined) {
            // do nothing
        } else {
            const fetchData = async () => {
                const apiIndividual = await getUserIndividual();
                setIndividual(apiIndividual);
                
                if (apiIndividual && apiIndividual.members) {
                    const coach_members: SerializableIndividual[] = apiIndividual.members.map(member => {
                        return {
                            id: member.id,
                            firstName: member.first_name,
                            lastName: member.last_name,
                            username: member.username,
                            history: [],
                        };
                    });

                    dispatch(setCoachMembers(coach_members));
                    dispatch(setCoachMemberUsernames(apiIndividual.members.map(member => member.username)));
                }
            };
            fetchData();
        }
    }, [auth, dispatch]);

    if (auth === undefined || auth.user === undefined) {
        window.location.assign('/login');

        return (<></>);
    }

    setAuthToken(auth.user.access_token);
    const user = auth.user;
    return (
        <div className="page">
            <header>
                <Link to={``} className="logo"><img alt="neuroFit" src="./site-images/logo_transparent.png" /></Link>
                <div className="user">{
                    (user ? <UserProfile /> : <></>)
                }</div>
            </header>
            <section>
                <div className="navigation">
                    <ul>
                        <li><NavLink to={``} className="dashboard"><IonIcon icon={podiumOutline} size="large"></IonIcon>Dashboard</NavLink></li>
                        <li><NavLink to={`scan`} className="scan"><IonIcon icon={speedometerOutline} size="large"></IonIcon>New Scan</NavLink></li>
                        <li hidden={!individual?.can_view_members}><NavLink to={`groups`} className="groups"><IonIcon icon={peopleOutline} size="large"></IonIcon>Groups</NavLink></li>
                        <li><NavLink to={`settings`} className="settings"><IonIcon icon={settingsOutline} size="large"></IonIcon>Settings</NavLink></li>
                    </ul>
                </div>
                <div className="content">
                    {user !== undefined ? <Outlet /> : <Navigate to="/login" replace />}
                </div>
            </section>
        </div>
    );
}

export default ProtectedRoutes;
