const convertDateForHistory = (date: Date): string => {
    return date.toISOString();
}

const formatDateForAnnotation = (date: string | undefined) => {
    if (date === undefined)
        return '';

    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString("en-US", { month: 'short', day: 'numeric' });
    return formattedDate;
};

const formatDateForChart = (inputDate: Date): string => {
    const date = new Date(inputDate);
    const offsetMinutes = date.getTimezoneOffset();
    const offsetExtra = offsetMinutes % 60;
    const offsetHours = (offsetMinutes / 60) * 100 + offsetExtra;

    return `${date.toUTCString()} ${offsetHours}`;
    
};

export {
    convertDateForHistory,
    formatDateForAnnotation,
    formatDateForChart,
};