import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerializableHistory } from '../../interfaces';

interface SelectedScanState {
    record: SerializableHistory | null;
}

const initialSelectionState: SelectedScanState = {
    record: null
};

const selectedScanSlice = createSlice({
    name: 'selected-scan',
    initialState: initialSelectionState,
    reducers: {
        selectScanResult(state, action: PayloadAction<SerializableHistory>) {
            state.record = {
                id: action.payload.id,
                date: action.payload.date,
                nFit: action.payload.nFit,
                annotation: action.payload.annotation,
            };
        },
        setAnnotation(state, action: PayloadAction<string>) {
            if (state.record !== null)
                state.record.annotation = action.payload;
        },
    }
});

export const { selectScanResult, setAnnotation } = selectedScanSlice.actions;
export default selectedScanSlice.reducer;
