import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { SerializableHistory, SerializableMemberHistory, nFitHistoryRecord } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { replaceMemberHistory } from '../../features/coach-members/coach-member-slice';
import { selectScanResult } from '../../features/selected-scan/selected-scan-slice';
import { getCoachMemberHistory } from '../../services/resource-server/resource-server';
import { convertDateForHistory, formatDateForChart } from '../../utilities/display-formats';

/* Color selections
   For now we just have a few manually added colors to alternate
   Going forward, we can populate a finite set, or consider creating a way to manage a higher number of colors through admin
*/
const lineColors = [
    { borderColor: 'rgba(0, 37, 193)', backgroundColor: 'rgba(0, 37, 193, 0.5)' },
    { borderColor: 'rgba(0, 193, 193)', backgroundColor: 'rgba(0, 193, 193, 0.5)' },
    { borderColor: 'rgba(193, 0, 37)', backgroundColor: 'rgba(193, 0, 37, 0.5)' },
];

const StripedBgPlugin = {
    id: 'StripedBg',
    beforeDraw: function (chart: any, args: any, options: any) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;

        const width = chartArea.right - chartArea.left;
        const height = chartArea.bottom - chartArea.top;

        const topMargin = chartArea.top;
        const leftMargin = chartArea.left; // 62;

        const numSections = 6;
        const colors = ['f0a06d', 'f7d19d', 'f1f6d5', 'f2f8fc', 'cae0dc', 'a6d5ad'];

        // reset to white on "redraw"
        ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
        ctx.fillRect(0, 0, width, height);

        var sectionHeight = Math.floor(height / numSections);
        ctx.lineWidth = sectionHeight;

        const edgeWidth = height - sectionHeight * numSections; // account for rounding errors
        for (let i = 0; i < numSections; i++) {
            const edge = Math.floor((i + 0.5) * sectionHeight);

            if (i === 0 || i === numSections - 1) {
                ctx.lineWidth += edgeWidth;
            }

            ctx.beginPath();
            ctx.strokeStyle = '#' + colors[5 - i];
            ctx.moveTo(leftMargin + 0, edge + topMargin);
            ctx.lineTo(leftMargin + width, edge + topMargin);
            ctx.stroke();
        }
    }
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    StripedBgPlugin
);

interface StructuredData {
    labels: string[];
    datasets: any[];
}

interface ChartProps {
    start: Date,
}

interface DataToChart {
    label: string;
    colorIndex: number;
    history: nFitHistoryRecord[];
}

function NFitGroupHistoryChart(props: ChartProps) {
    const coachMembers = useAppSelector((state) => state.coachMembers.members);
    const coachedUsernames = useAppSelector((state) => state.coachMembers.usernames);
    const dispatch = useAppDispatch();

    const { start } = props;

    const chartData = useAppSelector((state) => state.scanResults.chartIds);
    const graphedHistory = useAppSelector((state) => state.scanResults.history);
    let [data, setData] = useState<StructuredData>({ labels: [], datasets: [] });

    /*
    const setScanResult = (record: nFitHistoryRecord) => {
        dispatch(selectScanResult({
            id: record.id,
            date: record.date.toLocaleDateString(),
            nFit: parseFloat(record.nFit.toPrecision(4)),
            annotation: record.annotation || '',
        }));
    };
    */

    const options = {
        elements: {
            line: {
                borderWidth: 2
            }
        },
        onClick: function (evt: any, element: any) {
            // if a data point on the graph is selected, save it to state
            if (element.length > 0) {
                const record_id = chartData[element[0].index];
                const record = graphedHistory.find(h => h.id === record_id);
                if (record) {
                    /*
                    const annotatedString = record.annotation;
                    setScanResult({
                        id: record.id,
                        date: new Date(record.date),
                        nFit: parseFloat(record.nFit.toPrecision(4)),
                        annotation: annotatedString,
                    });
                    */
                }
            }
        },
        plugins:
        {
            legend: {
                display: false,
                position: 'top' as const,
                labels: {
                    usePointStyle: true,
                },
            },
            title: {
                display: false,
                text: 'nFit Score History',
            }
        },
        responsive: true,
        scales: {
            x: {
                ticks: {
                    font: {
                        family: "Poppins, sans-serif",
                        size: 10
                    }
                }
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: "Poppins, sans-serif",
                        size: 10
                    },
                    maxTicksLimit: 13
                }
            }
        }
    };

    const formatSeriesForDataSet = (useColor: number, label: string, history: nFitHistoryRecord[]) => {
        return {
            label: label,
            data: history.map((d) => d.nFit),
            borderColor: lineColors[useColor]['borderColor'],
            backgroundColor: lineColors[useColor]['backgroundColor'],
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            let mapData: StructuredData = {
                labels: [],
                datasets: []
            };

            const coachMemberHistory = await getCoachMemberHistory(coachedUsernames, start);

            let useColor = 0;
            let uniqueDates: Date[] = [];
            let dataToChart: DataToChart[] = [];
            coachMembers.map(member => {
                const history = coachMemberHistory.members.find(history => history.username === member.username, []);

                const member_with_history = {
                    id: member.id,
                    firstName: member.firstName,
                    lastName: member.lastName,
                    username: member.username,
                    history: history ? history.history.map(item => {
                        const objHistory: SerializableMemberHistory = {
                            id: item.id,
                            date: convertDateForHistory(item.date),
                            nFit: parseFloat(item.nFit.toPrecision(4)),
                            coachAnnotation: item.annotation,
                        };
                        return objHistory
                    }) : [],
                };

                // put this in the redux store
                dispatch(replaceMemberHistory(member_with_history));

                if (history && history.history.length > 0) {
                    const allDates = new Set([...uniqueDates, ...history.history.map(record => record.date)]);
                    uniqueDates = Array.from(allDates);
                    uniqueDates.sort((one, two) => new Date(one) > new Date(two) ? 1 : -1);
                    const newData: DataToChart = {
                        label: member.firstName,
                        colorIndex: useColor,
                        history: history.history,
                    };
                    dataToChart.push(newData);
                }
                
                useColor++;
                if (useColor > lineColors.length - 1)
                    useColor = 0;
            });

            // loop through dataToChart, format each series as a dataset, append
            const newMapData = dataToChart.map(data => {
                const spacedHistory = uniqueDates.map(date_object => {
                    const hasRecord = data.history.find(record => record.date === date_object);
                    if (hasRecord)
                        return hasRecord;

                    const spaceRecord: nFitHistoryRecord = {
                        id: -1,
                        date: date_object,
                        nFit: 0,
                        annotation: '',
                    };
                    return spaceRecord;
                });

                return formatSeriesForDataSet(data.colorIndex, data.label, spacedHistory);
            });
            mapData.labels = uniqueDates.map(date_object => formatDateForChart(date_object));
            mapData.datasets = newMapData;

            setData(mapData);
        }

        // finally make the call executing the above async code
        fetchData();
        
    }, [dispatch, start, coachedUsernames]);

    return (
        <Line
            options={options}
            data={data}
            plugins={[StripedBgPlugin]}
        />
    )
}

export default NFitGroupHistoryChart;