import { IonIcon } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';

import { useAuth } from "../../context";

import './protected-routes.css';

export default function UserProfile() {
    const auth = useAuth();
    
    if (!auth?.user) {
        return (<></>);
    }

    const logoutCallback = () => {
        // remove old behavior for now and redirect to OIDC server
        const env_oidc_server = process.env.REACT_APP_OIDC_SERVER;
        const env_app_hostname = process.env.REACT_APP_HOSTNAME;
        window.location.assign(`${env_oidc_server}logout?next=${env_app_hostname}autoLogin`);
    }
    const logout = () => {
        auth.logout(logoutCallback);
    };

    const username = new Date(auth.user.expires_at).toISOString(); // TODO get user profile from API

    return (
        <>
            <button className="profile" title={username} type="button"><IonIcon icon={personCircleOutline} size="large"></IonIcon></button>
            <button className="accountAction" onClick={() => logout()} type="button">Logout</button>
        </>
    );
}