import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerializableMemberHistory } from '../../interfaces';

export interface SerializableIndividual {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    history: SerializableMemberHistory[];
}

interface CoachMembersState {
    usernames: string[];
    members: SerializableIndividual[];
}

const initialState: CoachMembersState = {
    usernames: [],
    members: [],
};

const coachMembersSlice = createSlice({
    name: 'coach-members',
    initialState,
    reducers: {
        setCoachMembers: (state, action: PayloadAction<SerializableIndividual[]>) => {
            state.members = action.payload;
        },
        setCoachMemberUsernames: (state, action: PayloadAction<string[]>) => {
            state.usernames = action.payload;
        },
        replaceMemberHistory: (state, action: PayloadAction<SerializableIndividual>) => {
            const record = action.payload;
            const index = state.members.findIndex(h => h.id === record.id);
            const newArray = [
                ...state.members.slice(0,index),
                ...state.members.slice(index+1)
            ]
            newArray.push(record);

            state.members = newArray;
        },
    }
});

export const { setCoachMembers, setCoachMemberUsernames, replaceMemberHistory } = coachMembersSlice.actions;
export default coachMembersSlice.reducer;
