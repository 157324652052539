
import { useEffect, useState } from 'react';
import { ScoreSummary, Statistics } from '../components';
import { getUserProfile } from '../services/resource-server/resource-server';

export default function Dashboard() {
    let [profile, setProfile] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            let profile = await getUserProfile();
            setProfile(profile);
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="breadcrumb">
                neuroFit &gt; Dashboard
            </div>
            <div className="title">
                {profile ? `${profile?.first_name}'s` : 'Your'} Progress
            </div>
            <ScoreSummary />
            <Statistics />
        </>
    );
}