import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthCallback } from './components';
import { Dashboard, Groups, Login, ProtectedRoutes, Scan, Settings } from "./routes";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './context';
import { store } from './app/store';
import { Provider } from 'react-redux';

const router = createBrowserRouter([
    /*
    {
      path: "/",
      element: <Home />,
    },
    */
    {
        path: "/login",
        element: <Login auto={false} />,
    },
    {
        path: "/autologin",
        element: <Login auto={true} />,
    },
    {
        path: "/",
        element:
            <ProtectedRoutes />
        ,
        children: [
            {
                index: true,
                element: <Dashboard />
            },
            {
                path: "groups",
                element: <Groups />
            },
            {
                path: "scan",
                element: <Scan />
            },
            {
                path: "settings",
                element: <Settings />
            }
        ]
    },
    {
        path: "/openid/callback",
        element: <AuthCallback />
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    root.render(
        <AuthProvider>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </AuthProvider>
    );
} else {
    root.render(
        <React.StrictMode>
            <AuthProvider>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </AuthProvider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
