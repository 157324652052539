import { IonIcon } from '@ionic/react';
import { bookmarkOutline, trashOutline } from 'ionicons/icons';

import NFitGroupHistoryChart from "../Chart/nfit-history-group";

import "./group-summary.css";
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAnnotation } from '../../features/selected-scan/selected-scan-slice';
import { replaceHistory } from '../../features/scan-results/scan-result-slice';
import { updateUserAnnotation } from '../../services/resource-server/resource-server';
import { formatDateForAnnotation } from '../../utilities/display-formats';

export default function GroupSummary() {
    const defaultDate = new Date();
    // defaultDate.setDate(defaultDate.getDate() - 365);
    /* FOR testing with OLD data */
    defaultDate.setDate(defaultDate.getDate() - (6*365));

    let [start, setStart] = useState<Date>(defaultDate);
    const selectedScan = useAppSelector((state) => state.selectedScan.record);

    const dispatch = useAppDispatch();
    
    const setStartParameter = (daysBack: number) => {
        let newDate: Date = new Date();
        newDate.setDate(new Date().getDate() - daysBack);
        setStart(newDate);
    };

    const deleteAnnotation = () => {
        dispatch(setAnnotation(''));
        saveAnnotation('');
    };

    const trackEnteredAnnotation = (text: string) => {
        dispatch(setAnnotation(text));
    };

    const saveAnnotation = (text: string) => {
        if (selectedScan !== null) {
            const updatedScan = {
                ...selectedScan,
                annotation: text,
            };
            dispatch(replaceHistory(updatedScan));
            const sendData = async () => {
                await updateUserAnnotation(selectedScan.id, text);
            };
            sendData();
        }
    };

    return (
        <div className="drop-shadow-content-box nFitScores">
            <div className="controls">
                <div className="nFitScoreTitle">nFit</div>
                <button className="timePeriod" onClick={() => setStartParameter(365)}>1y</button>
                <button className="timePeriod" onClick={() => setStartParameter(90)}>3m</button>
                <button className="timePeriod" onClick={() => setStartParameter(7)}>1wk</button>
                <label><input type="checkbox" /> Global Comparison</label>
            </div>
            <div className="nFitGraph">
                <NFitGroupHistoryChart start={start} />
            </div>
            <div className="manageScores">
                <div className="annotationForm">
                    <div className="annotationInfo"><IonIcon icon={bookmarkOutline} size="medium"></IonIcon> Annotation {formatDateForAnnotation(selectedScan?.date)}</div>
                    <div className="annotationEntry">
                        <textarea
                            className="annotation"
                            title="" placeholder=""
                            onChange={event => trackEnteredAnnotation(event.target.value)}
                            onKeyDown={event => {
                                if (event.key === "Enter" && event.shiftKey === false) {
                                    event.preventDefault();
                                    // trigger sending annotation text to server
                                    saveAnnotation(selectedScan ? selectedScan.annotation : '');
                                }
                            }
                            }
                            value={selectedScan ? selectedScan.annotation : ''}></textarea>
                        <button className="trash" title="Delete"
                            onClick={event => deleteAnnotation()}><IonIcon icon={trashOutline} size="medium"></IonIcon></button>
                    </div>
                </div>
            </div>
        </div>
    );
}