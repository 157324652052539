const formatNFitForScreen = (score: number): number => {
    return parseFloat(score.toPrecision(4));
};

const getSelfReferencedScore = (score: number, median: number, sigma: number): number => {
    return (score - median) / sigma;
};

export {
    formatNFitForScreen,
    getSelfReferencedScore,
};