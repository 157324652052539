import { IonIcon } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { Tooltip } from 'react-tooltip';

import "./stat-card.css";
import { useEffect, useState } from 'react';

type Props = {
    key: number,
    score: string,
    dataLabel: string,
    delta: string,
    deltaUpDown: string
};

function getMappedLabel(dataLabel: string) {
    switch (dataLabel) {
        case 'INIT_latency': return 'response time';
        case 'INIT_accel': return 'acceleration';
        case 'SS_gain': return 'speed matching';
        case 'SS_sacc_amp': return 'jerk size';
        case 'SS_prop_smooth': return 'tracking smoothness';
        case 'DIR_anisotropy': return 'acircularity';
        case 'DIR_asymmetry': return 'asymmetry';
        case 'DIR_noise': return 'direction noise';
        case 'SPD_responsiveness': return 'speed perception';
        case 'SPD_noise': return 'speed noise';
        default: return `${dataLabel} (not mapped)`;
    }
}

const getMappedUnit = (dataLabel: string) => {
    switch (dataLabel) {
        case 'INIT_latency': return (' ms');
        case 'INIT_accel': return (<span> deg/s<sup>2</sup></span>);
        case 'SS_gain': return ('');
        case 'SS_sacc_amp': return (' deg');
        case 'SS_prop_smooth': return ('');
        case 'DIR_anisotropy': return ('');
        case 'DIR_asymmetry': return ('');
        case 'DIR_noise': return ('°');
        case 'SPD_responsiveness': return ('');
        case 'SPD_noise': return (' deg/s');
        default: return '';
    }
};

const getMappedToolTip = (dataLabel: string) => {
    switch (dataLabel) {
        case 'INIT_latency': return "<span class='tool-tip'>Latency and acceleration are two initiation metrics, driven by the motion of the target image on the retina sometimes called “retinal slip”. Latency is the elapsed time between motion onset and movement onset, measuring the combined effects of neural processing speed and motor responsiveness.</span>";
        case 'INIT_accel': return "<span>Acceleration is the rate of change of eye velocity as the eye begins moving  and can vary between sluggish (50 deg/s<sup>2</sup>) and quick (>150 deg/s<sup>2</sup>).</span>";
        case 'SS_gain': return "<span>Steady-state tracking is driven by the perception of object motion. Gain is the ratio of eye velocity to target velocity, 0.8 in normal up to 1.0 in athletes.</span>";
        case 'SS_sacc_amp': return "<span>Saccade amplitude measures the size of the small flicks that the eye makes during tracking, smaller saccades are better</span>";
        case 'SS_prop_smooth': return "<span>Proportion smooth is like gain, except it's a displacement ratio expressing the proportion of eye displacement during smooth pursuit as compared to total eye displacement, consisting of both saccades and smooth pursuit</span>";
        case 'DIR_anisotropy': return "<span>Direction-tuning metrics quantify perception of object direction around the unit circle. Anisotropy is a measurement of differences between direction perception along the cardinal axes (upward and downward), and the oblique axes (diagonals).</span>";
        case 'DIR_asymmetry': return "<span>Direction-tuning metrics quantify perception of object direction around the unit circle. Asymmetry is a measurement of the differences between upward-downward and leftward-rightward motion perception.</span>";
        case 'DIR_noise': return "<span>Direction noise quantifies the size of the error in perceived direction, lower numbers are better.</span>";
        case 'SPD_responsiveness': return "<span>Speed-tuning metrics quantify perception of object speed during steady-state tracking.  Speed responsiveness is an estimate of the ability to perceive speed, and drive the eye more quickly in response to faster motion.</span>";
        case 'SPD_noise': return "<span>Speed-tuning metrics quantify perception of object speed during steady-state tracking.  Speed noise is an estimate of the variability in perceived speed, lower numbers are better.</span>";
        default: return '';
    }
};

const StatCard = ({ dataLabel, score, delta, deltaUpDown }: Props) => {
    let [title, setTitle] = useState<string>('');
    let [unit, setUnit] = useState<any>('');
    let [toolTip, setToolTip] = useState<string>('');
    
    useEffect(() => {
        setTitle(getMappedLabel(dataLabel));
        setUnit(getMappedUnit(dataLabel));
        setToolTip(getMappedToolTip(dataLabel));
    }, [dataLabel]);

    return (
        <div className="drop-shadow-content-box statCard">
            <div className="cardDetails">
                <div className="cardTitle">{title}</div>
                <div className="cardScore">{score}{unit} <span className={`cardDelta ${deltaUpDown}`}>{delta}</span></div>
            </div>
            <div className="cardHelp">
                <button data-tooltip-id={dataLabel} data-tooltip-html={toolTip}><IonIcon icon={informationCircleOutline} size="medium"></IonIcon></button>
                <Tooltip id={dataLabel} />
            </div>
        </div>
    );
};

export default StatCard;