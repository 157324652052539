import { IonIcon } from '@ionic/react';
import { bookmarkOutline, trashOutline } from 'ionicons/icons';
import QRCode from "react-qr-code";

import NFitIndividualHistoryChart from "../Chart/nfit-history-individual";

import "./score-summary.css";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Modal } from '../../components';
import { setAnnotation } from '../../features/selected-scan/selected-scan-slice';
import { replaceHistory, setStartDate } from '../../features/scan-results/scan-result-slice';
import { updateUserAnnotation } from '../../services/resource-server/resource-server';
import { useAuth } from '../../context';
import { median, sigma } from '../../utilities/math';
import { formatNFitForScreen, getSelfReferencedScore } from '../../utilities/scoring-models';
import { formatDateForAnnotation } from '../../utilities/display-formats';

export default function ScoreSummary() {
    let [selfReferencingScore, setSelfReferencingScore] = useState<boolean>(false);
    let [rawNFitScore, setRawNFitScore] = useState<number>(0);
    let [nFitScore, setNFitScore] = useState<number>(0);
    let [myMedian, setMyMedian] = useState<number>(0);
    let [mySigma, setMySigma] = useState<number>(0);
    let [daysBack, setDaysBack] = useState<number>(365);
    const selectedScan = useAppSelector((state) => state.selectedScan.record);
    const [showModal, setShowModal] = useState<boolean>(false);
    const graphedHistory = useAppSelector((state) => state.scanResults.history);

    const auth = useAuth();
    const dispatch = useAppDispatch();
    
    const user = auth?.user;
    const qrCodeText = user.access_token;

    // calculate median / variance
    const calculateScoreVariables = () => {
        const nFitArray = graphedHistory.map(record => record.nFit);
        const calculatedMedian = median(nFitArray);
        const calculatedSigma = sigma(nFitArray);

        setMyMedian(calculatedMedian);
        setMySigma(calculatedSigma);
    };

    const setScoreByType = (nFit: number) => {
        let calculatedScore = nFit;
        if (selfReferencingScore) {
            calculatedScore = getSelfReferencedScore(nFit, myMedian, mySigma);
        }

        // store refined score
        setNFitScore(formatNFitForScreen(calculatedScore));
    };

    // on startup set to latest score
    useEffect(() => {
        if (graphedHistory !== undefined && graphedHistory.length > 0) {
            const latestRecord = [...graphedHistory].pop();
            if (latestRecord !== undefined) {
                setRawNFitScore(latestRecord.nFit);
            }
        }
    }, [graphedHistory]);

    // if raw data is populated or changed, update the chart data
    // if options change that affect the chart, update the chart data
    useEffect(() => {
        calculateScoreVariables();
    }, [graphedHistory, selfReferencingScore]);

    // on selection change, make sure raw score is accurate
    useEffect(() => {
        if (selectedScan) {
            setRawNFitScore(selectedScan.nFit);
        }
    }, [selectedScan]);

    // if selected score or chart option change would affect nFit score, update display
    useEffect(() => {
        setScoreByType(rawNFitScore);
    }, [rawNFitScore, selfReferencingScore]);

    const setStartParameter = (daysBack: number) => {
        setDaysBack(daysBack);
    };

    useEffect(() => {
        let newDate: Date = daysBack === -1 ? new Date('1900-01-01') : new Date();
        newDate.setDate(new Date().getDate() - daysBack);
        dispatch(setStartDate(newDate));
    }, [daysBack]);

    const deleteAnnotation = () => {
        dispatch(setAnnotation(''));
        saveAnnotation('');
    };

    const trackEnteredAnnotation = (text: string) => {
        dispatch(setAnnotation(text));
    };

    const saveAnnotation = (text: string) => {
        if (selectedScan !== null) {
            const updatedScan = {
                ...selectedScan,
                annotation: text,
            };
            dispatch(replaceHistory(updatedScan));
            const sendData = async () => {
                await updateUserAnnotation(selectedScan.id, text);
            };
            sendData();
        }
    };

    const hideModal = () => {
        setShowModal(false);
    };

    return (
        <div className="drop-shadow-content-box nFitScores">
            <div className="controls">
                <div className="nFitScoreTitle">nFit</div>
                <button className={daysBack === -1 ? "timePeriod selected" : "timePeriod"} onClick={() => setStartParameter(-1)}>all time</button>
                <button className={daysBack === 365 ? "timePeriod selected" : "timePeriod"} onClick={() => setStartParameter(365)}>1y</button>
                <button className={daysBack === 30 ? "timePeriod selected" : "timePeriod"} onClick={() => setStartParameter(30)}>1m</button>
                <button className={daysBack === 7 ? "timePeriod selected" : "timePeriod"} onClick={() => setStartParameter(7)}>1wk</button>
                <label><input type="checkbox" checked={selfReferencingScore} onChange={() => setSelfReferencingScore(!selfReferencingScore)} /> self-reference</label>
                <button className="showScan" onClick={() => setShowModal(true)}>New Scan</button>
            </div>
            <div className="nFitGraph">
                <NFitIndividualHistoryChart selfReferencingScore={selfReferencingScore} />
            </div>
            <div className="manageScores">
                <div className="overallScore">
                    <span className="overallScoreLabel">nFit score</span>
                    <div className={`numericOverallScore ${nFitScore > 0 ? "up" : "down"}`}>{nFitScore.toString()}</div>
                </div>
                <div className="annotationForm">
                    <div className="annotationInfo"><IonIcon icon={bookmarkOutline} size="medium"></IonIcon> Annotation {formatDateForAnnotation(selectedScan?.date)}</div>
                    <div className="annotationEntry">
                        <textarea
                            className="annotation"
                            title="" placeholder=""
                            onChange={event => trackEnteredAnnotation(event.target.value)}
                            onKeyDown={event => {
                                if (event.key === "Enter" && event.shiftKey === false) {
                                    event.preventDefault();
                                    // trigger sending annotation text to server
                                    saveAnnotation(selectedScan ? selectedScan.annotation : '');
                                }
                            }
                            }
                            value={selectedScan ? selectedScan.annotation : ''}></textarea>
                        <button className="trash" title="Delete"
                            onClick={event => deleteAnnotation()}><IonIcon icon={trashOutline} size="medium"></IonIcon></button>
                    </div>
                </div>
            </div>
            <Modal show={showModal} handleClose={hideModal}>
                <div className="qrCodeBorder">
                    <QRCode value={qrCodeText} />
                </div>
            </Modal>
        </div>
    );
}