

export default function Settings() {
    return (
        <>
            <div className="breadcrumb">
            neuroFit &gt; Settings
            </div>
            <div className="title">
            Settings
            </div>
            <div>SETTINGS</div>
        </>
    );
}