import './modal.css';

export interface QRCodeProps {
    handleClose: any;
    show: boolean;
    children: any;
}

const QRCode = ({ handleClose, show, children }: QRCodeProps) => {
    if (show) {
        return (
            <div className="modal">
                <section className="modal-main">)
                    <div className="modal-content">
                        {children}

                        <button className="modal-close" type="button" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </section>
            </div>
        );
    } else {
        return (<></>);
    }
};

export default QRCode;