import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";

const AuthCallback = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.loginCallback().then(() => {
      navigate("/");
    });
  }, [auth, navigate]);

  return <div>Processing signin...</div>;
};

export default AuthCallback;
