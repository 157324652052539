import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { nFitHistoryRecord } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setChartIDsAndHistory } from '../../features/scan-results/scan-result-slice';
import { selectScanResult } from '../../features/selected-scan/selected-scan-slice';
import { getNFitScoreHistory } from '../../services/resource-server/resource-server';
import { median, sigma } from '../../utilities/math';
import { getSelfReferencedScore } from '../../utilities/scoring-models';
import { convertDateForHistory, formatDateForChart } from '../../utilities/display-formats';

const StripedBgPlugin = {
    id: 'StripedBg',
    beforeDraw: function (chart: any, args: any, options: any) {
        const tickArray = chart.scales.y.ticks;
        const minTick = tickArray.reduce((accumulator: number, currentValue: any) => Math.min(accumulator, currentValue.value), 999);
        const maxTick = tickArray.reduce((accumulator: number, currentValue: any) => Math.max(accumulator, currentValue.value), -999);

        const fiveSectionRange: number = maxTick - minTick;

        const ctx = chart.ctx;
        const chartArea = chart.chartArea;

        const width = chartArea.right - chartArea.left;
        const height = chartArea.bottom - chartArea.top;

        // reset to white on "redraw"
        ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
        ctx.fillRect(0, 0, width, height);

        const topMargin = chartArea.top;
        const leftMargin = chartArea.left;

        const colors = ['0C8140', '75A377', 'F2F2F4', '75ACD4', '1376BC'];
        const colorStart = [100, 1.5, 0.5, -0.5, -1.5];
        const colorEnd = [1.5, 0.5, -0.5, -1.5, -100];

        let edge = 0;
        let filledSoFar = 0;
        for (let i = 0; i < colorStart.length; i++) {
            if (colorStart[i] >= minTick) {
                let chunk = 0;
                if (i === 0 && maxTick > colorEnd[i]) {
                    if (minTick > colorEnd[i]) {
                        chunk = maxTick - minTick;
                    } else {
                        chunk = maxTick - colorEnd[i];
                    }
                } else if (i === colors.length - 1 && minTick > colorEnd[i]) {
                    chunk = colorStart[i] - minTick;
                } else {
                    chunk = colorStart[i] - colorEnd[i];
                }
                let percent: number = chunk / parseFloat(fiveSectionRange.toFixed(20));
                if (percent > 1)
                    percent = 1;

                let sectionHeight = Math.floor(height * percent);

                if ((filledSoFar + sectionHeight) > height) {
                    sectionHeight = height - filledSoFar;
                }

                edge = Math.floor(filledSoFar + (sectionHeight * 0.5));
                filledSoFar += sectionHeight;

                let extra = 0;
                if (i === colors.length - 1 && filledSoFar <= height) {
                    extra = height - filledSoFar;
                }

                ctx.beginPath();
                ctx.strokeStyle = '#' + colors[i];
                ctx.lineWidth = sectionHeight + extra;
                ctx.moveTo(leftMargin + 0, edge + topMargin);
                ctx.lineTo(leftMargin + width, edge + topMargin);
                ctx.stroke();
            }
        }
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    StripedBgPlugin,
    TimeScale,
    zoomPlugin,
);

interface StructuredData {
    labels: string[];
    datasets: any[];
}

interface ChartProps {
    selfReferencingScore: boolean,
}

const defaultChartOptions = {
    elements: {
        line: {
            borderColor: 'rgb(50, 37, 193)',
            borderWidth: 2
        }
    },
    plugins:
    {
        legend: {
            display: false,
            position: 'top' as const,
            labels: {
                usePointStyle: true,
            },
        },
        title: {
            display: false,
            text: 'nFit Score History',
        },
        tooltip: {
            callbacks: {
                // overrides default tooltip title (x-axis) parse + render
                title: function (context: any) {
                    const date = DateTime.fromISO(context[0].raw.x.toISOString());
                    return date.toISO();
                },
                /*
                // can implement this override to change the tooltip from the default {image/value} (y-axis)
                label: function (context: any) {
                  const value = context.raw.y;
                  return `${value}`;
                }
                */
            }
        },
        zoom: {
            pan: {
                enabled: true,
                modifierKey: 'ctrl',
                mode: 'x',
            },
            zoom: {
                pinch: {
                    enabled: true,
                },
                drag: {
                    enabled: true,
                    threshold: 2,
                },
                wheel: {
                    enabled: true,
                },
                mode: 'x',
            }
        }
    },
    responsive: true,
    scales: {
        x: {
            ticks: {
                // implementing this will give you control over the x-axis labels
                // TODO currently this is only obtaining Date, not Time
                /*
                callback: function (value: any) {
                  console.log(`millis: ${value}`);
                  return DateTime.fromMillis(value as number).toISO();
                },
                */
                font: {
                    family: "Poppins, sans-serif",
                    size: 10
                }
            },
            type: 'time',
        },
        y: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    family: "Poppins, sans-serif",
                    size: 10
                },
                maxTicksLimit: 13
            }
        }
    }
};

function NFitIndividualHistoryChart(props: ChartProps) {
    const dispatch = useAppDispatch();

    const { selfReferencingScore } = props;

    // redux shared state
    const chartData = useAppSelector((state) => state.scanResults.chartIds);
    const graphedHistory = useAppSelector((state) => state.scanResults.history);
    const startDate = useAppSelector((state) => state.scanResults.startDate);

    // component state
    let [rawData, setRawData] = useState<nFitHistoryRecord[]>([]);
    let [populatedChartData, setPopulatedChartData] = useState<StructuredData>({ labels: [], datasets: [] });
    let [myMedian, setMyMedian] = useState<number>(0);
    let [mySigma, setMySigma] = useState<number>(0);
    let [chartOptions, setChartOptions] = useState<Object>(defaultChartOptions);
    let [selectedPoint, setSelectedPoint] = useState<number>(-1);

    const setScanResult = (record: nFitHistoryRecord) => {
        dispatch(selectScanResult({
            id: record.id,
            date: convertDateForHistory(record.date),
            nFit: record.nFit,
            annotation: record.annotation ? record.annotation : '',
        }));
    };

    const configureChart = () => {
        let options = Object.assign({}, defaultChartOptions, {
            'onClick': function (evt: any, element: any) {
                // if a data point on the graph is selected, save it to state
                if (element.length > 0) {
                    const record_index = element[0].index;
                    setSelectedPoint(record_index);
                }
            }
        });

        if (!selfReferencingScore) {
            // we add min / max to y-axis
            options = Object.assign(options, {
                scales: {
                    ...options.scales,
                    y: {
                        ...options.scales.y,
                        min: -3.0,
                        max: 3.0,
                    }
                }
            });
        }

        // use real data to set min/max on x-axis
        const rawDates = rawData.map(record => DateTime.fromISO(record.date.toISOString()));
        const minDate = DateTime.min(...rawDates);
        const maxDate = DateTime.max(...rawDates);
        if (minDate) {
            options = Object.assign(options, {
                plugins: {
                    ...options.plugins,
                    zoom: {
                        ...options.plugins.zoom,
                        limits: {
                            x: { min: minDate.toISO(), max: maxDate.toISO() },
                        }
                    }
                },
                scales: {
                    ...options.scales,
                    x: {
                        ...options.scales.x,
                        min: minDate.toISO(),
                    }
                }
            });
        }

        setChartOptions(options);
    };

    const getPlotPoint = (nFit: number) => {
        if (selfReferencingScore) {
            return getSelfReferencedScore(nFit, myMedian, mySigma);
        }

        // the raw score
        return nFit;
    };

    // on start up, fetch data, save to local state, and save Serializable copy to redux shared state
    useEffect(() => {
        const fetchData = async () => {
            const nFitScoreHistory = await getNFitScoreHistory(startDate);
            setRawData(nFitScoreHistory);
        }
        fetchData();
    }, [dispatch, getNFitScoreHistory, setRawData, startDate]);

    // send updated raw data to redux store
    useEffect(() => {
        dispatch(setChartIDsAndHistory(rawData.map(record => {
            return {
                id: record.id,
                date: convertDateForHistory(record.date),
                nFit: record.nFit,
                annotation: record.annotation,
            }
        })));
    }, [dispatch, rawData, setChartIDsAndHistory]);

    // if raw data is populated or changed
    // or options change that affect the chart
    // recalculate series variables
    useEffect(() => {
        const nFitArray = rawData.map(record => record.nFit);
        const calculatedMedian = median(nFitArray);
        const calculatedSigma = sigma(nFitArray);

        setMyMedian(calculatedMedian);
        setMySigma(calculatedSigma);
    }, [rawData, selfReferencingScore]);

    // raw data, use options, and calculated values affect the chart
    useEffect(() => {
        let pointRadiusArray = [];
        for (let i = 0; i < rawData.length; i++) {
            pointRadiusArray.push(i === selectedPoint ? 5 : 3);
        }

        const lineData: StructuredData = {
            labels: rawData.map((d) => formatDateForChart(d.date)),
            datasets: [
                {
                    data: rawData.map((d) => {
                        return {
                            y: getPlotPoint(d.nFit),
                            x: d.date,
                        }
                    }),
                    borderColor: 'rgb(0, 37, 193)',
                    backgroundColor: 'rgba(0, 37, 193, 0.5)',
                    pointRadius: pointRadiusArray,
                }
            ]
        };
        setPopulatedChartData(lineData);
    }, [rawData, selfReferencingScore, myMedian, mySigma, selectedPoint]);

    // if user selects a point on the graph
    useEffect(() => {
        const record_id = chartData[selectedPoint];
        const record = graphedHistory.find(h => h.id === record_id);
        if (record) {
            const annotatedString = record.annotation;
            setScanResult({
                id: record.id,
                date: new Date(record.date),
                nFit: record.nFit,
                annotation: annotatedString,
            });
        }
    }, [chartData, graphedHistory, selectedPoint]);

    // only after chart data is loaded can we correct for the onClick ChartOptions
    useEffect(() => {
        configureChart();
    }, [chartData, selfReferencingScore]);

    return (
        <Line
            options={chartOptions}
            data={populatedChartData}
            plugins={[StripedBgPlugin]}
            redraw={false}
        />
    );
}

export default NFitIndividualHistoryChart;