import { configureStore } from '@reduxjs/toolkit';
import coachMemberReducer from '../features/coach-members/coach-member-slice';
import scanResultReducer from '../features/scan-results/scan-result-slice';
import selectedScanReducer from '../features/selected-scan/selected-scan-slice';

export const store = configureStore({
  reducer: {
    coachMembers: coachMemberReducer,
    scanResults: scanResultReducer,
    selectedScan: selectedScanReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
