import { useAuth } from "../context";

interface Props {
    auto: boolean;
}

const Login = (props: Props) => {
  const auth = useAuth();
  const { auto } = props;

  if (auto) {
    auth.login();
  }

  const login = () => {
    auth.login();
  };
  return (
    <div className="container">
      <div className="headline">Click to login to SSO</div>
      <button onClick={() => login()}>Login</button>
    </div>
  );
};

export default Login;