import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerializableHistory } from '../../interfaces';

/*
We store an ordered list of scan_result.id in chartData
to match the order of the data, and the chart.js index
*/
interface ScanResultHistoryState {
    startDate: string;
    chartIds: number[];
    history: SerializableHistory[];
}

const defaultDate = new Date();
/* FOR testing with OLD data */
// defaultDate.setDate(defaultDate.getDate() - (6*365));
defaultDate.setDate(defaultDate.getDate() - (365));

const initialState: ScanResultHistoryState = {
    startDate: defaultDate.toISOString(),
    chartIds: [],
    history: []
};

const scanHistorySlice = createSlice({
    name: 'scan-result-history',
    initialState,
    reducers: {
        setChartIDsAndHistory: (state, action: PayloadAction<SerializableHistory[]>) => {
            state.chartIds = action.payload.map(record => record.id);
            state.history = action.payload;
        },
        setStartDate: (state, action: PayloadAction<Date>) => {
            state.startDate = action.payload.toISOString();
        },
        replaceHistory: (state, action: PayloadAction<SerializableHistory>) => {
            const record = action.payload;
            const index = state.history.findIndex(h => h.id === record.id);
            const newArray = [
                ...state.history.slice(0,index),
                ...state.history.slice(index+1)
            ]
            newArray.push(record);

            state.history = newArray;
        }
    }
});

export const { setChartIDsAndHistory, setStartDate, replaceHistory } = scanHistorySlice.actions;
export default scanHistorySlice.reducer;
