import { UserManager } from "oidc-client-ts";

export default class AuthService {
  userManager: UserManager;

  constructor() {
    const env_oidc_server = process.env.REACT_APP_OIDC_SERVER;
    const env_app_hostname = process.env.REACT_APP_HOSTNAME;
    const env_client_id = process.env.REACT_APP_OIDC_CLIENT_ID || "";
    const settings = {
      authority: `${env_oidc_server}o/`,
      client_id: env_client_id,
      redirect_uri: `${env_app_hostname}openid/callback`,
      post_logout_redirect_uri: `${env_app_hostname}login`,
      scope: "openid profile email",
    };
    this.userManager = new UserManager(settings);
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  loginCallback() {
    return this.userManager.signinRedirectCallback();
  }

  logout() {
    return this.userManager.signoutRedirect();    
  }

  renewToken() {
    return this.userManager.signinSilent()
  }
}
