import { instance } from "./axios-config";
import {
    endpoint_individual,
    endpoint_member_scans,
    endpoint_scanresult,
    endpoint_update_user_annotation,
    endpoint_user_individual,
    endpoint_user_profile,
    endpoint_user_type,
} from "../../constants";
import { nFitHistoryRecord } from "../../interfaces";

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        window.location.reload();
    }
    return error;
});

const setAuthToken = (token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const getNFitScoreHistory = async (start: string): Promise<Array<nFitHistoryRecord>> => {
    /* TODO
    limit up to ## results
    */
    return instance
        .get(`${endpoint_scanresult}?start=${start.split('T')[0]}`)
        .then(res => {
            const json_data = res.data;

            let nfit_history: Array<nFitHistoryRecord> = [];
            nfit_history = json_data.filter((record: any) => {
                return usesSupportedSchema(record);
            }).map((each_record: any) => {
                const nFitScore = getNFitScoreFromRecord(each_record);

                /* TODO: once we standardize where Scan Date is stored, we will stop accounting for variances here */
                const scan_date = each_record.scan_result.scan_date
                    ?? each_record.scan_result.date
                    ?? each_record.scan_result.scan?.date
                    ?? each_record.scan_result.scan_data?.date;

                let normalized_date = new Date(scan_date);
                if (JSON.stringify(normalized_date) === "null") {
                    // correct for "string" Unix timestamps
                    normalized_date = new Date(+scan_date);
                }

                let record: nFitHistoryRecord = {
                    "id": each_record.id,
                    "date": normalized_date,
                    "nFit": nFitScore,
                    "annotation": each_record.annotation,
                };
                return record;
            });

            nfit_history.sort((record1, record2) => {
                return (record1.date > record2.date ? 1 : -1);
            });

            return nfit_history;
        });
};

interface MemberHistory {
    username: string;
    history: Array<nFitHistoryRecord>;
}

interface CoachMemberHistory {
    members: Array<MemberHistory>;
}

const getMemberNFitScoreHistory = async (username: string, start: Date): Promise<MemberHistory> => {
    return instance
        .get(`${endpoint_member_scans}?username=${username}&start=${start.toISOString().split('T')[0]}`)
        .then(res => {
            const json_data = res.data;

            let nfit_history: Array<nFitHistoryRecord> = [];
            nfit_history = json_data.filter((record: any) => {
                return usesSupportedSchema(record);
            }).map((each_record: any) => {
                const nFitScore = getNFitScoreFromRecord(each_record);

                /* TODO: once we standardize where Scan Date is stored, we will stop accounting for variances here */
                const scan_date = each_record.scan_result.scan_date
                    ?? each_record.scan_result.date
                    ?? each_record.scan_result.scan?.date
                    ?? each_record.scan_result.scan_data?.date;

                let normalized_date = new Date(scan_date);
                if (JSON.stringify(normalized_date) === "null") {
                    // correct for "string" Unix timestamps
                    normalized_date = new Date(+scan_date);
                }

                let record: nFitHistoryRecord = {
                    "id": each_record.id,
                    "date": normalized_date,
                    "nFit": nFitScore,
                    "annotation": each_record.annotation,
                };
                return record;
            });

            nfit_history.sort((record1, record2) => {
                return (record1.date > record2.date ? 1 : -1);
            });

            const member_history: MemberHistory = {
                username: username,
                history: nfit_history,
            };
            return member_history;
        });
};

const getCoachMemberHistory = async (members: Array<string>, start: Date): Promise<CoachMemberHistory> => {
    let coach_history: CoachMemberHistory = {
        members: [],
    };
    for (let username of members) {
        const member_history: MemberHistory = await getMemberNFitScoreHistory(username, start);
        coach_history.members.push(member_history);
    }
    return coach_history;
};

const getScanResultById = async (id: number) => {
    return instance
        .get(`${endpoint_scanresult}${id}`)
        .then(res => {
            const record = res.data;
            
            if (hasScanResultCobraNFit(record))
                return record.scan_result.COBRA.metrics;
            if (hasScanDataNFit(record))
                return record.scan_result.scan_data.COBRA.nFit;
            if (hasScanCobraNFit(record))
                return record.scan_result.scan.COBRA.nFit;
            if (hasScanMetrics(record))
                return record.scan_result.scan.COBRA.metrics;
            if (hasProtocolData(record)) {
                return record.scan_result.scan_data.protocol_data.nFit;
            }

            return null;
        });
};

/* backwards compatibility */
const hasScanResultCobraNFit = (record: any): boolean => {
    return record.scan_result.COBRA !== undefined
        && record.scan_result.COBRA.metrics !== undefined
        && record.scan_result.COBRA.metrics.nFit !== undefined;
};

const hasScanDataNFit = (record: any): boolean => {
    return record.scan_result.scan_data !== undefined
        && record.scan_result.scan_data.COBRA !== undefined
        && record.scan_result.scan_data.COBRA.nFit !== undefined
        && record.scan_result.scan_data.COBRA.nFit.nFit !== undefined;
};

const hasScanCobraNFit = (record: any): boolean => {
    return record.scan_result.scan !== undefined
        && record.scan_result.scan.COBRA !== undefined
        && record.scan_result.scan.COBRA.nFit !== undefined
        && record.scan_result.scan.COBRA.nFit.nFit !== undefined;
};

const hasScanMetrics = (record: any): boolean => {
    return record.scan_result.scan !== undefined
        && record.scan_result.scan.COBRA !== undefined
        && record.scan_result.scan.COBRA.metrics !== undefined;
};

const hasProtocolData = (record: any): boolean => {
    return record.scan_result.scan_data !== undefined
        && record.scan_result.scan_data.protocol_data !== undefined
        && record.scan_result.scan_data.protocol_data.nFit !== undefined;
};

const usesSupportedSchema = (record: any): boolean => {
    return hasScanResultCobraNFit(record) || hasScanDataNFit(record) || hasScanCobraNFit(record)
        || hasScanMetrics(record) || hasProtocolData(record);
}

const getNFitScoreFromRecord = (record: any): any => {
    if (hasScanResultCobraNFit(record))
        return record.scan_result.COBRA.metrics.nFit;
    if (hasScanDataNFit(record))
        return record.scan_result.scan_data.COBRA.nFit.nFit;
    if (hasScanCobraNFit(record))
        return record.scan_result.scan.COBRA.nFit.nFit;
    if (hasScanMetrics(record))
        return record.scan.scan_data.COBRA.metrics.nFit;
    if (hasProtocolData(record)) {
        return record.scan_result.scan_data.protocol_data.nFit.nFit;
    }

    return null;
};

const getUserProfile = async () => {
    return instance
        .get(endpoint_user_profile)
        .then(res => {
            return res.data;
        });
};

const updateUserAnnotation = async (id: number, annotation: string) => {
    return instance
        .patch(`${endpoint_update_user_annotation}${id}/`, {
            annotation
        })
        .then(res => {
            return res.data;
        });
};

interface Member {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
}

interface IndividualUser {
    user_type: string;
    can_view_members: boolean;
    members: Member[];
}

const getIndividual = async (id: number): Promise<any> => {
    return instance
        .get(`${endpoint_individual}${id}/`)
        .then(res => {
            return res.data;
        });
};

const getUserIndividual = async (): Promise<IndividualUser> => {
    return instance
        .get(endpoint_user_individual)
        .then(res => {
            return res.data;
        });
};

const getUserType = async (id: number) => {
    return instance
        .get(`${endpoint_user_type}${id}/`)
        .then(res => {
            return res.data;
        });
};

export {
    setAuthToken,
    getCoachMemberHistory,
    getIndividual,
    getNFitScoreHistory,
    getScanResultById,
    getUserIndividual,
    getUserProfile,
    getUserType,
    updateUserAnnotation,
};